import getData from "@/__main__/get-data.mjs";
import type { RouteState } from "@/__main__/router.mjs";
import { saveToAllTips } from "@/game-val/actions.mjs";
import {
  getAgents,
  getMaps,
  getRelatedAgentTips,
  getRelatedMapTips,
  getTip,
  getTips,
} from "@/game-val/api.mjs";
import fetchConstants, {
  networkOptions,
} from "@/game-val/fetch-constants-data.mjs";
import type { Sides } from "@/game-val/guides-utils.mjs";
import { getGuideKeyStr } from "@/game-val/guides-utils.mjs";
import { AgentsModel } from "@/game-val/models/agents.mjs";
import { MapsModel } from "@/game-val/models/maps.mjs";
import {
  TipModel,
  TipsCollectionModel,
  TipsModel,
} from "@/game-val/models/tips.mjs";

const ORDER = ["title_ASC", "difficulty_DESC", "sys_publishedAt_ASC"];
const BACKOFF_TIME = 1 * 24 * 60 * 60 * 1000; // 1 day

export default async function fetchData(
  _params: string[],
  searchParams: URLSearchParams,
  state: RouteState,
) {
  const lineupParams = new URLSearchParams(searchParams);

  const page = (state.transient?.page as number) || 1;
  lineupParams.set("page", page.toString());

  if (!lineupParams.get("side")) {
    // Default to attacking
    lineupParams.set("side", "Attacking" satisfies Sides);
  }

  const requiredPromises: Promise<unknown>[] = [
    fetchConstants(),
    getData(
      getAgents(),
      // @ts-ignore
      AgentsModel,
      ["val", "cms", "agents"],
      networkOptions,
    ),
    // @ts-ignore
    getData(getMaps(), MapsModel, ["val", "cms", "maps"], networkOptions),
    getData(
      getTips({
        limit: 20,
        skip: (page - 1) * 20,
        side: lineupParams.get("side") || "Attacking",
        difficulty: lineupParams.get("difficulty")!,
        map: lineupParams.get("map")!,
        agent: lineupParams.get("agent")!,
        ability: lineupParams.get("ability")!,
      }),
      // @ts-ignore
      TipsCollectionModel,
      ["val", "tips", "list", getGuideKeyStr(lineupParams, true)],
      {
        shouldFetchIfPathExists: true,
        mergeFn(current, next) {
          return {
            ...next,
            items: [...current.items, ...next.items],
          };
        },
      },
    ).then(({ items }) => saveToAllTips(items)),
  ];

  const selectedLineup = lineupParams.get("lineup");

  if (selectedLineup) {
    // @ts-ignore
    const tip = await getData(getTip({ id: selectedLineup }), TipModel, [
      "val",
      "tips",
      "all",
      selectedLineup,
    ]);
    const agentKey = tip.agent.key;
    const map = tip.map.key;
    requiredPromises.push(
      getData(
        getRelatedAgentTips({
          limit: 4,
          order: ORDER,
          agent: agentKey,
          tipId: selectedLineup,
          map,
        }),
        // @ts-ignore
        TipsModel,
        ["val", "tips", "related", "agent", selectedLineup],
        {
          networkBackOffTime: BACKOFF_TIME,
        },
      ).then((tips) => saveToAllTips(tips)),
    );
    requiredPromises.push(
      getData(
        getRelatedMapTips({
          limit: 4,
          order: ORDER,
          agent: agentKey,
          tipId: selectedLineup,
          map,
        }),
        // @ts-ignore
        TipsModel,
        ["val", "tips", "related", "map", selectedLineup],
        {
          networkBackOffTime: BACKOFF_TIME,
        },
      ).then((tips) => saveToAllTips(tips)),
    );
  }

  await Promise.all(requiredPromises);
}
